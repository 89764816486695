<template>
  <div class="container">
    <div class="terms">
      <h2>Terms and Conditions</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped>
.container {
  background-color: #dcdfe5;
}

.terms {
  position: relative;
  text-align: left;
  padding: 45px 20px;
  color: #000;
}

h2 {
  font-family: Roboto, sans-serif;
  font-size: 1.7em;
}

p,
li {
  font-family: Roboto, sans-serif;
  font-size: 0.8em;
}
</style>
