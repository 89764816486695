<template>
  <div class="home">
    <div class="busyBuilding" />
    <div class="hello" />
    <div class="watchThisSpace" />
    <div class="footer">
      <div class="center">
        &copy;{{ '\xa0' }}2021{{ '\xa0' }}atFirstSite{{ '\xa0' }}App{{ '\xa0' }}Ltd. All{{
          '\xa0'
        }}rights{{ '\xa0' }}reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
}
</script>

<style scoped>
.home {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      162.44deg,
      #116763 0%,
      #00a99d 52.08%,
      #ade3e0 100%
  );
}
.busyBuilding {
  position: absolute;
  top: calc(50% - 65px - 150px);
  left: calc(50% - 120px);
  height: 94px;
  width: 282px;
  background: url("~@/assets/busy-building.svg") no-repeat;
}

.hello {
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 143px);
  height: 150px;
  width: 276px;
  background: url("~@/assets/logo.svg") no-repeat;
}

.watchThisSpace {
  position: absolute;
  top: calc(50% + 28px + 100px);
  left: calc(50% - 120px);
  height: 56px;
  width: 240px;
  background: url("~@/assets/watch-this-space.svg") no-repeat;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  /*height: 40px;*/
  font-family: "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  font-size: small;
  padding: 5px 0px 5px 0px;
  color: white;
  background: #093432;
}

.center {
  display: flex;
  justify-content: center;
}
</style>
