import {createRouter, createWebHistory} from 'vue-router'
import HomePage from "@/components/HomePage.vue";
import AppReferrals from "@/components/AppReferrals.vue";
import TermsAndConditions from "@/components/mobile/TermsAndConditions.vue";
import PrivacyPolicy from "@/components/mobile/PrivacyPolicy.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: HomePage,
    },
    {
        path: "/referrals",
        name: "Referrals",
        component: AppReferrals,
    },
    {
        path: "/mobile/privacy",
        name: "Privacy Policy",
        component: PrivacyPolicy,
    },
    {
        path: "/mobile/terms",
        name: "Terms and Conditions",
        component: TermsAndConditions,
    },
    // {
    //     path: "/:catchAll(.*)",
    //     redirect: "/",
    // },
];

const routerHistory = createWebHistory();

const router = createRouter({
    history: routerHistory,
    routes: routes
});


export default router;
