<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/*#app {*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  background: linear-gradient(*/
/*      162.44deg,*/
/*      #116763 0%,*/
/*      #00a99d 52.08%,*/
/*      #ade3e0 100%*/
/*  );*/
/*}*/
</style>
